import { add } from '../../../_modules/i18n';
import nl from './nl';
import fa from './fa';
import de from './de';
import ru from './ru';
export default function () {
    add(nl, 'nl');
    add(fa, 'fa');
    add(de, 'de');
    add(ru, 'ru');
}
